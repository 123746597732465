import React from "react";

const Navbar = () => {
	const appNav = (
		<section id="home-section" className="slider1">
			<div className="tp-banner-container">
				<div className="tp-banner" >
					<ul>
						<li data-transition="fade" data-slotamount="7" data-masterspeed="500" data-saveperformance="on" data-title="Intro Slide">
							<img src="assets/images/daxpharma/almacenes001-new.jpg" alt="slidebg1" data-lazyload="assets/images/daxpharma/almacenes001-new.jpg" data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat" />

							<div className="tp-caption finewide_medium_white lfl tp-resizeme rs-parallaxlevel-0"
								data-x="0"
								data-y="305"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="1600"
								data-easing="Power3.easeInOut"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.05"
								data-endelementdelay="0.05"
								style={{ zIndex: '8', whiteSpace: 'nowrap', color: '#375b8b' }}>
							</div>

						</li>
						<li data-transition="fade" data-slotamount="7" data-masterspeed="500" data-saveperformance="on" data-title="Intro Slide">
							<img src="assets/images/daxpharma/almacenes002-new.jpg" alt="slidebg1" data-lazyload="assets/images/daxpharma/almacenes002-new.jpg" data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat" />

							<div className="tp-caption finewide_medium_white lfl tp-resizeme rs-parallaxlevel-0"
								data-x="0"
								data-y="305"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="1600"
								data-easing="Power3.easeInOut"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.05"
								data-endelementdelay="0.05"
								style={{ zIndex: '8', whiteSpace: 'nowrap' }}>
							</div>

						</li>
					</ul>
					<div className="tp-bannertimer"></div>
				</div>
			</div>
		</section>
	);

	return appNav;
};

export default Navbar;
