import React from "react";

const Products = () => {
    const appNav = (
        <section id="services-section">

            <div className="container">
                <div className="services-box">
                    <div className="col-md-6 col-md-offset-6">

                        <div className="inner-services-box">
                            <div className="title-section">
                                <h1>Servicios</h1>
                            </div>
                            <div className="services-items">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="services-post">
                                            <span><i className="fa fa-university"></i></span>
                                            <h2>Servicio de <br /> almacenamiento</h2>
                                            <ul>
                                                <li>Certificado en Buenas Prácticas de Almacenamiento</li>
                                                <li>Áreas exclusivas y compartidas</li>
                                                <li>Áreas para la recepción, almacenamiento y despacho de productos farmacéuticos, dispositivos médicos y productos sanitarios</li>
                                                <li>Áreas que se ajustan a los requerimientos de los clientes</li>
                                                <li>Almacén con temperatura controlada (15ºC - 25ºC)</li>
                                                <li>Ubicación accesible</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="services-post">
                                            <span><i className="fa fa-pencil"></i></span>
                                            <h2>Asesoría <br /> regulatoria</h2>
                                            <ul>
                                                <li>Inscripciones, reinscripciones y modificaciones de tu registro sanitario</li>
                                                <li>Asesoramiento en apertura  de droguerías</li>
                                                <li>Asesoramiento en certificaciones en Buenas Prácticas de Almacenamiento (BPA)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="services-post">
                                            <span><i className="fa fa-file"></i></span>
                                            <h2>Certificados <br /> </h2>
                                            <br /> 
                                            <ul>
                                                <li><a data-toggle="modal" href='#modal-id'>Certificado BPA</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="modal fade" id="modal-id">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                            <h4 className="modal-title text-bold">Certificado BPA</h4>
                        </div>
                        <div className="modal-body text-center">
                            <img src="assets/images/daxpharma/CERTIFICADO_BPA.jpg" className="img-responsive" alt="Certificado BPA" style={{ width: '70vw' }} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    );

    return appNav;
};

export default Products;
