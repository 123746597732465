import React from "react";
import axios from "axios";

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            lastname: '',
            phone: '',
            email: '',
            subject: '',
            comment: ''
        }
    }

    onNameChange(event) {
        this.setState({ name: event.target.value })
    }
    onLastnameChange(event) {
        this.setState({ lastname: event.target.value })
    }
    onPhoneChange(event) {
        this.setState({ phone: event.target.value })
    }
    onEmailChange(event) {
        this.setState({ email: event.target.value })
    }
    onSubjectChange(event) {
        this.setState({ subject: event.target.value })
    }
    onCommentChange(event) {
        this.setState({ comment: event.target.value })
    }

    handleSubmit = (event) => {
        console.log(this.state);

        const params = {
            subject: this.state.subject,
            name: this.state.name,
            lastname: this.state.lastname,
            phone: this.state.phone,
            email: this.state.email,
            comment: this.state.comment
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Accept-encoding': 'gzip, deflate',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(params)
        };

        fetch('https://www.jerefrigeracion.org/emailOther', requestOptions)
            .then(response => console.log(response));

        event.preventDefault();
    }

    render() {
        return (
            <section id="contact-section">
                <div className="container">
                    <div className="title-section">
                        <h1>Contáctanos</h1>
                    </div>
                    <div className="contact-box">
                        <div className="row">
                            <div className="col-md-8 col-sm-8">
                                <form id="contact-form" onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input name="name" id="name" type="text" placeholder="Nombres*" required value={this.state.name} onChange={this.onNameChange.bind(this)} />
                                        </div>
                                        <div className="col-md-6">
                                            <input name="name" id="lastname" type="text" placeholder="Apellidos*" required value={this.state.lastname} onChange={this.onLastnameChange.bind(this)} />
                                        </div>
                                        <div className="col-md-4">
                                            <input name="name" id="phone" type="text" placeholder="Celular*" required value={this.state.phone} onChange={this.onPhoneChange.bind(this)} />
                                        </div>
                                        <div className="col-md-4">
                                            <input name="mail" id="mail" type="text" placeholder="Correo*" required value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                                        </div>
                                        <div className="col-md-4">
                                            <input name="subject" id="subject" type="text" placeholder="Asunto*" required value={this.state.subject} onChange={this.onSubjectChange.bind(this)} />
                                        </div>
                                    </div>
                                    <textarea name="comment" id="comment" placeholder="Mensaje" style={{ resize: 'none' }} required value={this.state.comment} onChange={this.onCommentChange.bind(this)}></textarea>
                                    <input type="submit" id="submit_contact" value="Enviar" />
                                    <div id="msg" className="message"></div>
                                </form>
                            </div>
                            <div className="col-md-3 col-md-offset-1 col-sm-4">

                                <div className="contact-post">
                                    <i className="fa fa-location-arrow" aria-hidden="true"></i>
                                    <div className="content-post">
                                        <h2>Dirección</h2>
                                        <p>Jr. Mateo Aguilar 220. Interior 301. <br />
                                     Urbanización Ingeniería. SMP, Lima</p>
                                    </div>
                                </div>

                                <div className="contact-post">
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                    <div className="content-post">
                                        <h2>Contacto</h2>
                                        <p>daxpharmasac@gmail.com</p>
                                    </div>
                                </div>

                                <div className="contact-post">
                                    <i className="fa fa-clock-o" aria-hidden="true"></i>
                                    <div className="content-post">
                                        <h2>Horario</h2>
                                        <p>Lunes a Viernes     8:00am - 18:00pm <br /> Sábado     8:00am - 13:00pm</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15608.770063149033!2d-77.0590083!3d-12.0302636!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf080146c54039da5!2sDAXPHARMA%20S.A.C.!5e0!3m2!1ses-419!2spe!4v1619111606919!5m2!1ses-419!2spe" width="100%" height="450" style={{ border: '0' }} aria-hidden="false"></iframe>
            </section>
        );
    }
}

// const Contact = () => {
//     const appNav = (

//     );

//     return appNav;
// };

export default Contact;

// import React from 'react';

// export default class extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = { feedback: '', name: 'Name', email: 'email@example.com' };
//         this.handleChange = this.handleChange.bind(this);
//         this.handleSubmit = this.handleSubmit.bind(this);
//     }

//     render() {
//         return (
//             <form className="test-mailing">
//                 <h1>Let's see if it works</h1>
//                 <div>
//                     <textarea
//                         id="test-mailing"
//                         name="test-mailing"
//                         onChange={this.handleChange}
//                         placeholder="Post some lorem ipsum here"
//                         required
//                         value={this.state.feedback}
//                         style={{ width: '100%', height: '150px' }}
//                     />
//                 </div>
//                 <input type="button" value="Submit" className="btn btn--submit" onClick={this.handleSubmit} />
//             </form>
//         )
//     }

//     handleChange(event) {
//         this.setState({ feedback: event.target.value })
//     }

//     handleSubmit(event) {
//         const templateId = 'template_id';

//         this.sendFeedback(templateId, { message_html: this.state.feedback, from_name: this.state.name, reply_to: this.state.email })
//     }

//     sendFeedback(templateId, variables) {
//         window.emailjs.send(
//             'gmail', templateId,
//             variables
//         ).then(res => {
//             console.log('Email successfully sent!')
//         })
//             // Handle errors here however you like, or use a React error boundary
//             .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
//     }
// }
