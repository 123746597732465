import React from "react";

const Footer = () => {
    const appNav = (
        <footer style = {{paddingTop:'0px'}}>
            <p className="copyright">
                &copy; Copyright 2020. Todos los derechos reservados.
			</p>
        </footer>
    );

    return appNav;
};

export default Footer;
