import React from "react";

const Navbar = () => {
    const appNav = (
        <header className="clearfix trans">
            <nav className="navbar navbar-default navbar-fixed-top" role="navigation">
                <div className="container">
                    <div className="inner-header">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <a className="navbar-brand" href="index.html"><img src="assets/images/daxpharma/daxpharma.png" alt="" width="180px" /></a>
                        </div>
                        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                            <ul className="nav navbar-nav navbar-right navigate-section">
                                <li><a className="active" href="#home-section">Inicio</a></li>
                                <li><a href="#about-section">Nosotros</a></li>
                                <li><a href="#services-section">Servicio</a></li>
                                <li><a href="#contact-section">Contacto</a></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </nav>
        </header>
    );

    return appNav;
};

export default Navbar;
