import React from 'react';
import Header from "./components/Header/index";
import Slider from "./components/Slider/index";
import About from "./components/About/index";
import Products from "./components/Products/index";
import Contact from "./components/Contact/index";
import Footer from "./components/Footer/index";
import './App.css';

function App() {
  return (
    <>
      <Header />
      <Slider />
      <About />
      <Products />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
