import React from "react";

const About = () => {
    const appNav = (
        <section id="about-section">
            <div className="container">
                <div className="title-section">
                    <h1>¿Quiénes somos?</h1>
                    <p style={{color: "black"}}>
                        Somos una empresa certificada en Buenas Prácticas de Almacenamiento que almacena con calidad y seguridad los productos
                        <br />
                        de nuestros clientes, asesorándolos desde el inicio de sus actividades hasta la comercialización de sus productos.
                    </p>
                </div>
                <div className="about-box">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="about-post">
                                <img src="assets/images/daxpharma/mision.jfif" className="img-responsive" alt="Image" width="70px" />
                                <div className="about-post-content">
                                    <h2>Misión</h2>
                                    <p style={{color: "black"}}>
                                        Brindar servicio de almacenamiento de calidad y seguridad a nuestros clientes asesorándolos desde el inicio de sus actividades hasta la comercialización de sus productos.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="about-post">
                                <img src="assets/images/daxpharma/vision.png" className="img-responsive" alt="Image" width="70px" />
                                <div className="about-post-content">
                                    <h2>Visión</h2>
                                    <p style={{color: "black"}}>
                                        Ser una empresa líder en la calidad y seguridad del servicio de almacenamiento y comercializar productos innovadores en el mercado.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    );

    return appNav;
};

export default About;
